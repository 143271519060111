<template>
    <v-card
        :height="height"
        :width="width"
        :opacity="1"
        dense
        :flat="flat">
        <v-row 
            v-if="!hideHeader"
            class="pa-2"
            dense
            small>
            <v-menu 
                v-if="showSettings || canExportCSV"
                offset-y
                :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        icon
                        v-bind="attrs"
                        v-on="on"
                        title="Settings"
                        small>
                        <v-icon small>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-if="canExportCSV" @click="exportAsCSV" dense>
                        <v-list-item-icon>
                            <v-icon small>mdi-file-delimited-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="canRefresh" @click="refresh" dense>
                        <v-list-item-icon>
                            <v-icon small>mdi-refresh</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Refresh</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="mGraphType != 2" />
                    <v-subheader v-if="mGraphType != 2">Chart</v-subheader>
                    <v-list-item-group v-if="mGraphType != 2" v-model="mChartType" mandatory>
                        <v-list-item v-for="(chart, index) in chartTypes" :key="('c' + index)">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ chart }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <v-divider />
                    <v-subheader>Graph</v-subheader>
                    <v-list-item-group v-model="mGraphType" @change="refreshChartData" mandatory>
                        <v-list-item v-for="(gType, index) in graphTypes" :key="('g' + index)">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ gType }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <v-divider v-if="isLengthyArray(filteredXSpreadOptions)" />
                    <v-subheader v-if="isLengthyArray(filteredXSpreadOptions)">Spread</v-subheader>
                    <v-list-item-group v-model="mXSpread" @change="refreshChartData" mandatory>
                        <v-list-item v-for="(xSpread, index) in filteredXSpreadOptions" :key="('s' + index)">
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ xSpread.text }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    <slot name="settings" />
                </v-list>
            </v-menu>

            <v-menu
                v-if="showFilters && isLengthyArray(chipFilters)"
                :close-on-content-click="false"
                dense
                left
                bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-icon small>mdi-filter</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item-group
                        v-model="selectedFilters"
                        multiple>
                        <template v-for="(f, i) in chipFilters">
                            <v-list-item
                                :key="i"
                                :value="f">
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-icon v-if="active" small>mdi-check</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="f" />
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                    <v-fade-transition hide-on-leave group>
                        <v-divider v-if="isFilterChanged" key="1"/>
                        <v-btn
                            v-if="isFilterChanged"
                            key="2"
                            text
                            small
                            class="mx-auto"
                            @click="refresh">
                            <v-icon small left>mdi-filter</v-icon>Apply
                        </v-btn>
                    </v-fade-transition>
                </v-list>
            </v-menu>
            <span class="ml-1 my-auto">{{ title }}</span>
            <v-spacer v-if="!hideSpanOptions" />
            <v-menu v-if="!hideSpanOptions" offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        dense
                        small>
                        {{ mXSpan != null ? filteredXSpanOptions[mXSpan].text : 'Select' }}
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item-group v-model="mXSpan">
                        <v-list-item v-for="(xSpanOpt, index) in filteredXSpanOptions" :key="index">
                            <v-list-item-content>{{ xSpanOpt.text }}</v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            <slot name="toolbar-right" />
        </v-row>
        <v-slide-y-transition group hide-on-leave>
            <v-alert v-model="showError" dismissible type="error" key="3">{{ errorMessage }}</v-alert>

            <div v-if="mGraphType==2" key="1.1" align="center">
                <v-progress-circular
                    :color="color"
                    :size="circleSize"
                    :value="mTotal">
                    <span :style="'font-size: ' + circleFontSize">{{ circlePrefix }}{{ displayText }}</span>
                </v-progress-circular>
            </div>
            <div v-else key="1.2">
                <Bar
                    v-if="mChartType == 0"
                    :chartOptions="chartOptions"
                    :chartData="chartData"
                    chartID="mChart"
                    :height="height - 90"
                    :styles="chartStyles" />
                <LineChart
                    v-else-if="mChartType == 1"
                    :chartOptions="chartOptions"
                    :chartData="chartData"
                    chartID="mChart"
                    :height="height - 90"
                    :styles="chartStyles" />
            </div>

            <div key="3.4" v-if="showTable">
                <slot name="table" v-bind="{ items: filteredItems, allItems: asyncItems }">
                    <v-data-table
                        dense
                        :headers="tableHeaders"
                        :items="filteredItems"
                        :hide-default-footer="!showFooter"
                        :hide-default-header="hideTableHeader"
                        :items-per-page="itemsPerPage"
                        mobile-breakpoint="10"
                        @click:row="selectItem"
                        key="4">

                        <template v-for="(filter, index) in allDisplayFilters" v-slot:[`item.${filter.value}`]="{ item }">
                            <td :key="index">
                                <slot :name="filter.value" v-bind:item="item">
                                    <div v-if="filter.bool == true">
                                        <v-icon
                                            v-if="getNestedValue(item, filter.value) === true"
                                            icon
                                            small>mdi-check</v-icon>
                                    </div>
                                    <div
                                        v-else-if="filter.textFilter != null && filter.selfIsValue === true"
                                        :class="filter.class">{{ $options.filters[filter.textFilter](item) }}</div>
                                    <div
                                        v-else-if="filter.textFilter != null"
                                        :class="filter.class">{{ $options.filters[filter.textFilter](getNestedValue(item, filter.value)) }}</div>
                                    <div
                                        v-else
                                        :class="filter.class">Dummy</div>
                                </slot>
                            </td>
                        </template>
                    </v-data-table>
                </slot>
            </div>
            <v-overlay :value="isLoading" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>Loading</p>
            </v-overlay>

        </v-slide-y-transition>
        
        <BT-Snack v-model="msg" />
    </v-card>
</template>

<script>
//import { roundUp } from '~helpers';
import { Bar, Line as LineChart } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js';
import { firstBy } from 'thenby';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement);

export default {
    name: 'BT-Graph-Card',
    components: {
        Bar,
        LineChart
    },
    data: function() {
        return {
            asyncItems: [],
            chartData: {
                labels: [],
                datasets: []
            },
            chartTypes: ['Bar', 'Line'],
            errorMessage: null,
            id: null,
            isFilterChanged: false,
            isLoading: false,
            mChartType: 0,
            mGraphType: 1,
            mParams: { includeDetails: false },
            mProxyID: null,
            mXSpan: 0,
            mXSpread: 0,
            mTotal: 0,
            msg: null,
            originalFilters: [],
            recentConditions: null, //that last set of conditions to have been pulled ({ ...params, ...navigation, ...bladeData, ...proxyID })
            selectedFilters: [],
            select: null,
            selectedItem: null,
            showDelete: false,
            showError: false
        }
    },
    props: {
        addBladeName: null,
        canExportCSV: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        circlePrefix: {
            type: String,
            default: null
        },
        circleSize: {
            type: String,
            default: '200'
        },
        circleFontSize: {
            type: String,
            default: '25px'
        },
        color: {
            type: String,
            default: null
        },
        csvIncludeTitle: {
            type: Boolean,
            default: false
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [] } //{ filter: String, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        customURL: {
            type: String,
            default: null
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        defaultChartType: {
            type: String,
            default: 'Line'
        },
        defaultGraphType: {
            type: String,
            default: 'Gradual' //gradual, total, category
        },
        defaultXSpan: {
            type: String,
            default: 'This Week'
        },
        defaultXSpread: {
            type: String,
            default: 'Daily'
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Array,
            default: null
        },
        flat: {
            type: Boolean,
            default: false
        },
        getParams: {
            type: Function,
            default: null
        },
        graphTypes: {
            type: Array,
            default: () => { return ['Category','Gradual','Total']}
        },
        headers: {
            type: Array, //{ text, textFilter, value, valueFilter, breakdown, csv, csvArray, csvText, csvFilter, csvBreakdown, csvProductIDProp }
            default: null
        },
        height: {
            type: Number,
            default: 300
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideHorizontals: {
            type: Boolean,
            default: false
        },
        hideLegend: {
            type: Boolean,
            default: false
        },
        hideSpanOptions: {
            type: Boolean,
            default: false
        },
        hideTableHeader: {
            type: Boolean,
            default: false
        },
        hideVerticals: {
            type: Boolean,
            default: false
        },
        hideXLabels: {
            type: Boolean,
            default: false
        },
        hideYLabels: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 75
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: 'CreatedOn'
        },
        itemValue: { // null = count
            type: String,
            default: null
        },
        lines: {
            type: Array,
            default: () => {
                return [{ text: 'Line', itemValue: null, textFilter: null }]
            }
        },
        legendPosition: {
            type: String,
            default: 'top'
        },
        loading: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onFilter: {
            type: Function,
            default: null
        },
        onGetLabels: {
            type: Function,
            default: null
        },
        onGetQuery: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        
        onSelect: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showTable: {
            type: Boolean,
            default: false
        },
        textFilter: {
            type: String,
            default: 'toDisplayNumber'
        },
        textFunction: {
            type: Function,
            default: null
        },
        title: null,
        useListItems: {
            type: Boolean,
            default: true
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        wholeNumbers: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 400
        },
        xProp: {
            type: String,
            default: 'CreatedOn'
        },
        xSpanOptions: {
            type: Array,
            default: () => { return [
                { text: 'Today' },
                { text: 'This Week' },
                { text: 'Last 7 Days' },
                { text: 'This Month' },
                { text: 'Last 30 Days' },
                { text: 'Last 100 Days' },
                { text: 'All' }
            ]}
        },
        xSpreadOptions: {
            type: Array,
            default: () => { return [
                { text: 'Hourly' },
                { text: 'Daily' },
                { text: 'Weekly' },
                { text: 'Monthly' },
                { text: 'Yearly' },
                { text: 'Hour Of Day' },
                { text: 'Day Of Week' },
                { text: 'Day Of Month' },
                { text: 'Week' },
                { text: 'Month' },
                { text: 'Count' },
                { text: 'Value' }
            ]}
        },
        xType: {
            type: String,
            default: 'Date', //String, (Category?)
        },
        yProp: {
            type: String,
            default: null //if null, calc count
        }
    },
    watch: {
        items: function() {
            this.pullItems();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
        selectedFilters: function(val) {
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
        mXSpan: function(val) {
            //make sure spread is valid
            if (val != null) {
                this.pullItems();
            }
        }
    },
    created() {
        this.isLoading = this.loading;
    },
    mounted() {
        if (this.params != null) {
            this.mParams = this.params;
        }

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                var fInd = this.chipFilters.findIndex(y => y == dFilter);
                if (!this.selectedFilters.some(x => x == fInd)) {
                    this.selectedFilters.push(fInd);
                }
            })
            
            this.originalFilters = this.selectedFilters;
        }

        if (this.defaultXSpan != null) {
            this.xSpan = this.filteredXSpanOptions.findIndex(x => x.text == this.defaultXSpan);
        }

        this.mChartType = this.chartTypes.findIndex(x => x == this.defaultChartType);
        this.mGraphType = this.graphTypes.findIndex(x => x == this.defaultGraphType);
        this.mXSpan = this.filteredXSpanOptions.findIndex(x => x.text == this.defaultXSpan);
        this.mXSpread = this.filteredXSpreadOptions.findIndex(x => x.text == this.defaultXSpread);

        this.pullItems();
    },
    computed: {
        allDisplayFilters() {
            return this.tableHeaders.filter(x => x.textFilter != null || x.display != null || x.breakdown === true || x.bool === true); // || this.displayFilters.some(y => y == x.value));
        },
        chartOptions() {
            return {
                responsive: true,
                plugins: {
                    legend: {
                        position: this.hideLegend ? null : this.legendPosition
                    }
                },
                scales: {
                    x: {
                        grid: {
                            drawBorder: !this.hideVerticals
                        },
                        ticks: {
                            display: !this.hideXLabels
                        }
                    },
                    y: {
                        grid: {
                            drawBorder: !this.hideHorizontals
                        },
                        ticks: {
                            display: !this.hideYLabels,
                            ticks: {
                                stepSize: this.wholeNumbers ? 1 : null
                            }
                        }
                    }
                }
            }
        },
        chartStyles() {
            return {
                height: `${this.height - 150}px`,
                position: 'relative',
                responsive: true
            }
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            if (this.useServerFilters && this.navigation != null) {
                filterList.push(...this.$BlitzIt.store.filters(this.navigation));
            }

            return filterList;
        },
        csvItems() { //{ csv: true, () => {}, string,  }
            //return this.headers;
            if (!this.isLengthyArray(this.headers)) {
                return [];
            }
            return this.headers
                //.filter(y => (y.csvText != null || (y.text != null && y.csv !== false)) && (y.csv != undefined || y.csvFilter != undefined))
                .filter(y => ((y.csvText != undefined || y.text != undefined) && y.csv != undefined) || y.csv != undefined || y.csvText != undefined || y.csvFilter != undefined || y.csvBreakdown != undefined || y.csvArray != undefined)
                .map(z => {
                    return Object.assign({}, z, {
                        header: z.csvText != null ? z.csvText : z.text,
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv,
                        valueFilter: z.csvFilter,
                        breakdown: z.csvBreakdown === true,
                        csvArray: z.csvArray === true
                    });
                });
        },
        displayText() {
            var res = this.mTotal;
            if (this.textFunction != null) {
                res = this.textFunction(res);
            }
            if (this.textFilter != null) {
                res = this.$options.filters[this.textFilter](res);
            }

            return res;
        },
        showFooter() {
            return !this.hideFooter && this.isLengthyArray(this.asyncItems) && this.asyncItems.length > this.itemsPerPage;
        },
        filteredItems() {
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
        },
        filteredXSpanOptions() {
            var l = [
                { 
                    text: 'Today', 
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                { 
                    text: 'This Week',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('week').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.$BlitzIt.auth.createRawTZ().endOf('week').toUTC().toString()
                },
                {
                    text: 'Last 7 Days',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ days: -7, minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                { 
                    text: 'This Month',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('month').plus({ minutes: -1 }).toUTC().toString(),
                    to: this.$BlitzIt.auth.createRawTZ().endOf('month').toUTC().toString()
                },
                { 
                    text: 'Last 30 Days',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ days: -30, minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                {
                    text: 'Last 100 Days',
                    from: this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ days: -100, minutes: -1 }).toUTC().toString(),
                    to: this.getTomorrow()
                },
                {
                    text: 'All',
                    from: null,
                    to: null
                },
            ];

            return this.xSpanOptions.map(x => {
                return Object.assign({}, l.find(y => y.text == x.text), x);
            });
        },
        filteredXSpreadOptions() {
            if (this.mXSpan == null) {
                return [];
            }

            var l = [
                { 
                    text: 'Hourly',
                    filter: 'Gradual',
                    spans: ['Today'],
                    puffDefaultLength: 12,
                    puffModifier: (val) => { return val.minus({ hours: 1 }) },
                    yModifier: (val) => { return val.startOf('hour'); },
                    xFormatModifier: (val) => { return val.toFormat('dd MMM h a') }
                },
                { 
                    text: 'Daily',
                    filter: 'Gradual',
                    spans: ['Today','This Week','This Month','Last 7 Days','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 7,
                    puffModifier: (val) => { return val.minus({ days: 1 }) },
                    yModifier: (val) => { return val.startOf('day'); },
                    xFormatModifier: (val) => { return val.toFormat('EEE d MMM') }
                },
                { 
                    text: 'Weekly',
                    filter: 'Gradual',
                    spans: ['This Month','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 7,
                    puffModifier: (val) => { return val.minus({ weeks: 1 }) },
                    yModifier: (val) => { return val.startOf('week').minus({ days: 1 }); },
                    xFormatModifier: (val) => { return val.toFormat('W') }
                },
                { 
                    text: 'Monthly',
                    filter: 'Gradual',
                    spans: ['Last 100 Days'],
                    puffDefaultLength: 7,
                    puffModifier: (val) => { return val.minus({ months: 1 }) },
                    yModifier: (val) => { return val.startOf('month'); },
                    xFormatModifier: (val) => { return val.toFormat('MMM yy') }
                },
                { 
                    text: 'Yearly',
                    filter: 'Gradual',
                    spans: ['This Year', 'Last 365 Days'],
                    puffDefaultLength: 3,
                    puffModifier: (val) => { return val.minus({ years: 1 }) },
                    yModifier: (val) => { return val.startOf('year'); },
                    xFormatModifier: (val) => { return val.toFormat('yyyy') },
                },
                { 
                    text: 'Hour Of Day',
                    filter: 'Category',
                    spans: ['Today','This Week','This Month', 'Last 7 Days','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 24,
                    yModifier: (val) => { return val.hour; },
                    xFormatModifier: (val) => { return val.toFormat('h a') }
                    // puff: (values) => {
                    //     //sort
                    //     for (let i = 0; i < 24; i++) {
                    //         if (!values.some(x => x.x == i)) {
                    //             values.splice(i, 0, { x: i, y: 0 });
                    //         }
                    //     }
                    //     values.sort(firstBy(x => x.x));
                        
                    //     values.forEach(v => {
                    //         v.x = v.x.toFormat('h a');
                    //     })

                    //     return values;
                    // }
                },
                { 
                    text: 'Day Of Week',
                    filter: 'Category',
                    spans: ['This Week','This Month', 'Last 7 Days','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 7,
                    yModifier: (val) => { return val.weekday; },
                    xFormatModifier: (val) => { return val.toFormat('EEE') }
                },
                { 
                    text: 'Day Of Month',
                    filter: 'Category',
                    spans: ['This Week','This Month', 'Last 7 Days','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 32,
                    yModifier: (val) => { return val.day; },
                    xFormatModifier: (val) => { return val.toFormat('d') },
                },
                { 
                    text: 'Week',
                    filter: 'Category',
                    spans: ['This Month','Last 30 Days','Last 100 Days'],
                    puffDefaultLength: 53,
                    yModifier: (val) => { return val.weeknumber; },
                    xFormatModifier: (val) => { return val.toFormat('W') }
                },
                { 
                    text: 'Month',
                    filter: 'Category',
                    spans: ['This Year', 'Last 365 Days'],
                    puffDefaultLength: 12,
                    yModifier: (val) => { return val.month; },
                    xFormatModifier: (val) => { return val.toFormat('MMM') }
                    // puff: (values) => {
                    //     //sort
                    //     for (let i = 1; i < 13; i++) {
                    //         if (!values.some(x => x.x == i)) {
                    //             values.splice(i, 0, { x: i, y: 0 });
                    //         }
                    //     }
                    //     values.sort(firstBy(x => x.x));
                        
                    //     values.forEach(v => {
                    //         v.x = v.x.toFormat('MMM');
                    //     })

                    //     return values;
                    // }
                },
                { 
                    text: 'Count',
                    filter: 'Total',
                    spans: ['Today','This Week','This Month', 'Last 7 Days','Last 30 Days','Last 100 Days','All']
                },
                { 
                    text: 'Value',
                    filter: 'Total',
                    spans: ['Today','This Week','This Month', 'Last 7 Days','Last 30 Days','Last 100 Days','All']
                },
            ];

            var gType = this.graphTypes[this.mGraphType];
            var sType = this.filteredXSpanOptions[this.mXSpan].text;
            var r = this.xSpreadOptions.map(x => {
                return Object.assign({}, l.find(y => y.text === x.text), x);
            })
            
            return r.filter(x => x.filter == gType && x.spans.some(span => span == sType));
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        tableHeaders() {
            var rHeaders = [];
            if (this.isLengthyArray(this.headers)) {
                for (var i = 0; i < this.headers.length; i++) {
                    if (this.headers[i].text != null) {
                        rHeaders.push(this.headers[i]);
                    }
                }
            }
            if (!this.hideActions) {
                rHeaders.push({ text: 'Actions', value: 'actions', align: 'right', width: this.actionsWidth || 'auto' });
            }
            return rHeaders;
        }
    }, 
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        conditionsHaveChanged() { //checks and returns true or false, always ending with updating the recentConditions with the currentConditions
            var currentConditions = {
                ...this.getParamObj(),
                navigation: this.navigation,
                itemID: this.itemID,
                proxyID: this.proxyCompanyID
            }
            
            var currentJSON = JSON.stringify(currentConditions);
            var isDif = currentJSON != this.recentConditions;
            this.recentConditions = currentJSON;
            return isDif;
        },
        async exportAsCSV() {
            //csvIncludeTitle
            if (this.isLengthyArray(this.asyncItems)) {
                var csvKeys = this.csvItems;
                
                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.asyncItems[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var csvList = [];
                this.filteredItems.forEach(aItem => {
                    var newItem = {};
                    var otherLines = [];
                    csvKeys.forEach(csvDNA => {
                        //object
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        if (csvDNA.csvArray) {
                            if (this.isLengthyArray(v)) {
                                v.forEach(w => {
                                    otherLines.push(w.toString());
                                })
                            }
                        }
                        else {
                            newItem[csvDNA.header] = v;
                        }
                    });
                    csvList.push(newItem);

                    if (this.isLengthyArray(otherLines)) {
                        otherLines.forEach(l => {
                            csvList.push(l);
                        })
                    }
                    
                    otherLines = [];

                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitle ? this.title : null);
            }
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            var query = '';
            if (this.isLengthyArray(this.selectedFilters)) {
                var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    else {
                        serverFilters.push(sFilter);
                    }
                });

                if (this.isLengthyArray(serverFilters)) {
                    paramObj.filterBy = serverFilters.toString();
                }
                
                if (this.isLengthyArray(customFilters)) {
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })
                }
            }

            if (this.onGetQuery != null) {
                query = this.onGetQuery(this.itemText, this.filteredXSpanOptions[this.mXSpan]);
            }
            else if (this.itemText != null) {
                var from = this.filteredXSpanOptions[this.mXSpan].from;
                var to = this.filteredXSpanOptions[this.mXSpan].to;

                if (from != null) {
                    query = query + (query.length > 0 ? 'ANDALSO' : '') + `${this.itemText}>${from}`;
                }
                if (to != null) {
                    query = query + (query.length > 0 ? 'ANDALSO' : '') + `${this.itemText}<${to}`;
                }
            }

            if (query.length > 0) {
                paramObj.query = query;
            }

            var itProps = [];
            if (this.isLengthyArray(this.itemProperties)) {
                this.itemProperties.forEach(itP => {
                    itProps.push(itP);
                })
            }

            if (this.itemText != null) {
                if (!itProps.some(itemProp => itemProp == this.itemText)) {
                    itProps.push(this.itemText);
                }
            }
            
            if (this.isLengthyArray(itProps)) {
                if (!itProps.some(x => x == 'ID')) {
                    itProps.push('ID');
                }
                paramObj.properties = this.copyDeep(itProps).toString();
            }

            // if (this.useServerPagination) {
            //     paramObj.includeCount = true;
            //     paramObj.takeAmount = this.itemsPerPage;
            //     paramObj.takeFrom = (this.currentPage - 1) * this.itemsPerPage;
            // }

            return paramObj;
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }

            if (Number.isNaN(this.mXSpan)) {
                return;
            }
            
            if (this.items != null) {
                this.asyncItems = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                this.refreshChartData();

                this.$emit('fetched', this.asyncItems);

                return;
            }

            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.startLoading();

                var paramObj = this.getParamObj();

                var res = null;
                if (this.isSingle) {
                    var idParam = this.itemID != null ? this.itemID : this.id;
                    res = await this.$BlitzIt.store.get(this.navigation, idParam, paramObj, refresh, this.proxyCompanyID, this.customURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID, this.customURL);
                }
                
                res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh, this.bladeData);
                }
                else {
                    this.asyncItems = res;
                }

                this.refreshChartData();
                
                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refreshChartData() {
            var labels = [];
            var datasets = [];
            var auth = this.$BlitzIt.auth;

            if (this.mXSpread < 0 || this.mXSpread >= this.filteredXSpreadOptions.length) {
                this.mXSpread = 0;
            }
            
            if (Number.isNaN(this.mXSpan)) {
                return;
            }
            
            if (this.mGraphType === 2) {
                if (this.filteredXSpreadOptions[this.mXSpread].text == 'Value') {
                    this.mTotal = this.filteredItems.sum(x => this.getNestedValue(x, this.itemValue));
                }
                else if (this.filteredXSpreadOptions[this.mXSpread].text == 'Count') {
                    this.mTotal = this.filteredItems.length;
                }
            }
            else {
                var dItems = this.copyDeep(this.filteredItems);
                dItems.sort(firstBy(x => this.getNestedValue(x, this.itemText)));

                if (this.isLengthyArray(dItems)) {
                    this.lines.forEach(line => {
                        if (line.itemValue == null) {
                            line.itemValue = this.itemValue;
                        }
                        if (line.borderColor == null) {
                            line.borderColor = [
                                'rgba(255, 99, 132, 1)'
                            ]
                        }
                        if (line.backgroundColor == null) {
                            line.backgroundColor = [
                                'rgba(255, 99, 132, 0.2)'
                            ]
                        }

                        var xSpread = this.filteredXSpreadOptions[this.mXSpread];
                        if (xSpread != null) {
                            if (xSpread.yModifier == null) {
                                xSpread.yModifier = (val) => { return val; };
                            }
                            
                            const lineVals = dItems.map(x => {
                                return {
                                    x: xSpread.yModifier(auth.createRawTZ(this.getNestedValue(x, this.itemText))),
                                    y: line.itemValue != null ? this.getNestedValue(line.itemValue) : 1
                                };
                            })
                            
                            const groups = lineVals.reduce((groups, dItem) => {
                                const date = dItem.x;
                                if (!groups[date]) {
                                    groups[date] = [];
                                }
                                groups[date].push(dItem);
                                return groups;
                            }, {});

                            // Edit: to add it in the array format instead
                            const groupArrays = Object.keys(groups).map(x => {
                                return {
                                    x: x,
                                    y: groups[x].sum(a => a.y)
                                };
                            });

                            groupArrays.sort(firstBy(x => x.x));

                            //puff
                            if (xSpread.filter == 'Gradual') {
                                if (groupArrays.length == 1) {
                                    //puff to minimum
                                    var m = auth.createRawTZ(groupArrays[0].x);
                                    groupArrays[0].x = m;
                                    for (let i = 1; i < xSpread.puffDefaultLength; i++) {
                                        m = xSpread.puffModifier(m);
                                        groupArrays.unshift({
                                            x: m,
                                            y: 0
                                        });
                                    }
                                }
                                else {
                                    var min = auth.createRawTZ(groupArrays[0].x);
                                    var max = auth.createRawTZ(groupArrays[groupArrays.length - 1].x);

                                    groupArrays[0].x = min;
                                    groupArrays[groupArrays.length - 1].x = max;
                                    var cInd = groupArrays.length - 2;

                                    if (max > min) {
                                        do {
                                            max = xSpread.puffModifier(max);
                                            var eInd = groupArrays.findIndex(y => y.x == max);
                                            if (eInd < 0) {
                                                groupArrays.splice(cInd, 0, { x: max, y: 0 });
                                            }
                                            else {
                                                groupArrays.splice(eInd, 0, { x: max, y: 0 });
                                            }
                                        } while (min < max);
                                    }
                                }
                            }
                            else if (xSpread.filter == 'Category') {
                                //fixed length

                            }

                            if (xSpread.xFormatModifier != null) {
                                groupArrays.forEach(gArr => {
                                    gArr.x = xSpread.xFormatModifier(gArr.x);
                                })
                            }

                            if (!this.isLengthyArray(labels)) {
                                //fill labels once
                                groupArrays.forEach(v => {
                                    labels.push(v.x.toString());
                                })
                            }

                            var dSet = {
                                label: line.text,
                                data: groupArrays.map(y => y.y)
                            }

                            datasets.push(dSet);
                        }
                    })
                    
                    this.chartData = {
                        labels: labels,
                        datasets: datasets
                    };
                }
            }
            
        },
        refresh(refresh = true) {
            this.originalFilters = this.selectedFilters;
            this.isFilterChanged = JSON.stringify(this.selectedFilters) != JSON.stringify(this.originalFilters);
            this.showSearch = false;
            this.showError = false;
            this.errorMessage = null;

            this.pullItems(refresh);
        },
        reset() {
            this.originalFilters = [];
            this.selectedFilters = [];
            
            if (this.isLengthyArray(this.defaultFilters)) {
                this.defaultFilters.forEach(dFilter => {
                    this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
                })
                this.originalFilters = this.selectedFilters;
            }
            
            this.$BlitzIt.store.clear(this.navigation);
            this.pullItems(true);
        },
        selectItem(item) {
            if (!this.canSelect || (this.onCanSelect != null && !this.onCanSelect(item))) {
                return;
            }
            
            if (this.onSelect != null) {
                this.onSelect(this.bladeData, item);
            }
            else if (item == null) {
                if (this.bladesData != null) {
                    this.bladesData.closeBlade({ bladeName: this.addBladeName });
                }
            }
            else {
                var selectBlade = { 
                    bladeName: this.addBladeName,
                    data: { id: item.id },
                    query: {},
                    clearBlades: this.clearBlades, 
                    clearOtherBlades: this.clearOtherBlades ? this.bladeName: null,
                    bladesToClear: this.bladesToClear
                };

                if (this.getSelectBladeData != null) {
                    selectBlade.data = Object.assign({}, selectBlade.data, this.getSelectBladeData(this.bladeData, item, this.computedMeasurements));
                }

                if (this.proxyCompanyID != null) {
                    selectBlade.query.proxyID = this.proxyCompanyID;
                }

                if (this.getSelectQuery != null) {
                    selectBlade.query = Object.assign({}, selectBlade.query, this.getSelectQuery(this.bladeData, item, this.computedMeasurements));
                }
                
                if (this.onCanOpenBlade != null) {
                    var str = this.onCanOpenBlade(selectBlade);
                    if (str === false) {
                        return;
                    }
                    else if (typeof(str) === 'string') {
                        this.msg = str;
                        return;
                    }
                }

                if (this.bladesData == null) {
                    this.$router.push({ 
                        name: selectBlade.bladeName, 
                        params: selectBlade.data, 
                        query: selectBlade.query 
                    });
                }
                else {
                    this.bladesData.openBlade(selectBlade);

                    if (this.minimizeOnSelect == true && !this.isPinned) {
                        this.bladesData.minimizeBlade({ bladeName: this.bladeName });
                    }
                }
            }
        }
    }
}
</script>